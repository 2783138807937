/*
 * Castledown Web Fonts from colophon-foundry.org
 *
 * The fonts included in this stylesheet are subject to the End User License purchased
 * from Colophon Foundry. The fonts are protected under domestic and international trademark and
 * copyright law. You are prohibited from modifying, reverse engineering, duplicating, or
 * distributing this font software.
 *
 * (c) 2019 Colophon Foundry
 *
 * Licenced to Stanley Huang, Techyon
 */

@font-face {
  font-family: "Castledown";
  src: url("../src/static/fonts/castledown-heavy.eot");
  src: url("../src/static/fonts/castledown-heavy.eot#iefix")
      format("embedded-opentype"),
    url("../src/static/fonts/castledown-heavy.woff2") format("woff2"),
    url("../src/static/fonts/castledown-heavy.woff") format("woff"),
    url("../src/static/fonts/castledown-heavy.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  src: url("../src/static/fonts/Inter-Regular.woff2") format("woff2"),
    url("../src/static/fonts/Inter-Regular.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  src: url("../src/static/fonts/Inter-Medium.woff2") format("woff2"),
    url("../src/static/fonts/Inter-Medium.woff") format("woff");
}

@font-face {
  font-family: "Inter";
  font-style: normal;
  font-weight: 700;
  src: url("../src/static/fonts/Inter-Bold.woff2") format("woff2"),
    url("../src/static/fonts/Inter-Bold.woff") format("woff");
}

html {
  height: 100%;
  margin: 0;
  padding: 0;
  --scroll-behavior: smooth;
  scroll-behavior: smooth;
  box-sizing: border-box;
  overflow-x: hidden;
}

body {
  margin: 0;
  padding: 0;
  opacity: 1;
  overflow-x: hidden;
  visibility: visible;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

#flipbook .odd {
  border-top-right-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-right: 2px;
}

#flipbook .even {
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  margin-left: 2px;
}

#flipbook .page:not(.hard) {
  box-shadow: -2px 0 2px -2px rgb(0 0 0 / 25%), 2px 0 2px -2px rgb(0 0 0 / 25%) !important;
  margin-top: 2px;
}
